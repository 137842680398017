.subCategoryButton {
  font-size: 16px;
  line-height: 16px;
  color: #931964;
  background: #FFFFFF;
  border-radius: 56px;
  box-shadow: 10px 12px 80px rgba(100, 0, 97, 0.2);
  margin-right: 8px;
  margin-bottom: 8px;
  padding: 18px 24px;
  display: inline-block;
  transition: box-shadow .4s ease;

  @media only screen and (max-width: 767px) {
    font-size: 14px;
  }
}

.subCategoryButton:hover {
  text-decoration: none;
  box-shadow: 10px 28px 80px rgb(100 0 97 / 23%);
  z-index: 1;
}

.subCategoryButton.active {
  color: #FFFFFF;
  background: linear-gradient(71.03deg, #640061 24.74%, #DE4169 155.32%);
  border-radius: 64px;
  padding: 18px 31px;
}

