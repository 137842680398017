.global-loader {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes spin {
  100% {
      transform: rotate(1turn);
  }
}

.global-loader .circle {
  animation: spin 4s linear infinite;
}

.global-loader .loading-logo {
  position: fixed;
  width: 100px;
}
