// Override default variables before the import
// you can open the bootstrap.scss module and check any available variables.
$primary: #5A145D;
$secondary: #8f5325;
$success: #3e8d63;
$info: #7854e4;
$warning: #b8c924;
$danger: #d62518;
$light: #f8f9fa;
$dark: #343a40;

//custom color to be mapped
$accent: #da6d25;

// this is used to map colors. change accordingly.
$theme-colors: (
  primary: $primary,
  secondary: $secondary,
  success: $success,
  info: $info,
  warning: $warning,
  danger: $danger,
  light: $light,
  dark: $dark,
  // add any additional color below
  accent: $accent,
  // now bg-accent,btn-accent,etc.. can be called
);

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700;800&display=swap");

body {
  background-color: #ffffff;
  color: #3d4857;
  font-size: 14px;
  line-height: 16px;
  font-family: 'Open Sans', sans-serif;
}

p {
  margin: 0;
}

a,
button.link {
  color: $primary;
  text-decoration: none;
}

a:hover,
button.link:hover {
  color: $primary;
  text-decoration: underline;
}

button.link {
  overflow: visible; // Shrinkwrap the text in IE7-
  margin: 0;
  padding: 0;
  border: 0;
  background: transparent;
  text-align: var(--bs-body-text-align);
}

h1 {
  font-size: 64px;
  line-height: 88px;
  font-weight: 800;
  margin-bottom: 16px;
}

h2 {
  margin-bottom: 0;
}

h2.page-headline {
  color: #dd4169;
  font-size: 56px;
  font-weight: 800;
  line-height: 88px;
}

.cursor-pointer {
  cursor: pointer;
}

@media only screen and (min-width: 768px) {
  .w-md-auto {
    width: auto !important;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1120px;
  }
}

.header__nav {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-top: 24px;

  @media only screen and (max-width: 520px) {
    display: block;
  }
}

.header__logo img {
  height: 50px;
  width: 93px;
}

.header__backlink, .header__contact {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 16px;
}

.header__links {
  @media only screen and (max-width: 520px) {
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: baseline;
  }
}


.layout__description {
  background: url(../../public/images/header.png) no-repeat;
  background-size: auto;
  background-size: 1440px;
  background-position-x: 0%;
  background-position-x: center;

  & .content {
    padding-top: 84px;

    @media only screen and (max-width: 767px) {
      padding-top: 60px;
    }
  }

  & .layout__projects-total {
    font-size: 24px;
    line-height: 32px;

    @media only screen and (max-width: 767px) {
      font-size: 18px;
      line-height: 24px;
    }
  }

  @media only screen and (max-width: 767px) {
    background-position-x: 30%;
    background-position-y: -225px;
  }

}

.layout__h1 {
  color: #640061;

  >span {
    color: #dd4068;
  }
}

.layout__main-categories {
  margin-top: 104px !important;
  margin-bottom: 56px;

  @media only screen and (max-width: 767px) {
    margin-top: 40px;
    margin-bottom: 40px;
  }
}

.layout__categories-with-icons {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;

  @media only screen and (max-width: 1200px) {
    box-shadow: 10px 28px 80px rgba(100, 0, 97, 0.23);
    border-radius: 16px;
    background-color: #fff;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    gap: 0;
  }
}

.layout__data #projects-list {
  background: #FFFFFF;
  box-shadow: 10px 12px 80px rgba(100, 0, 97, 0.2);
  border-radius: 16px;
  margin: 0 -12px;

  .load-more {
    text-align: center;

    button {
      text-transform: uppercase;
      color: #ffffff;
      margin: 16px;
      font-size: 1rem;
      font-weight: bold;
      border-radius: 2rem;
      padding: 0.5rem 3rem;
      line-height: 2rem;
      background: linear-gradient(89.19deg, #c71c48 0%, #dd4068 101.83%);
    }
  }
}

.layout__data #pagination {
  color: #5A145D;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  padding: 15px 0;
}

.layout__data #pagination a {
  display: inline-block;
  width: 48px;
  height: 48px;
  padding: 14px 2px 0 0;
}

.layout__data #pagination .actual {
  color: #ffffff;
  background: linear-gradient(71.03deg, #640061 24.74%, #DE4169 155.32%);
  border-radius: 30px;
}

.layout__data-title__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 40px;

  @media only screen and (max-width: 767px) {
    justify-content: center;
  }
}

.layout__data-selects {
  display: inline-flex;
  & div:first-child {
    margin-right: 20px;
  }
}

.col.category__sub-categories {
  margin-top: 96px;
  margin-bottom: 32px;

  @media only screen and (max-width: 767px) {
    margin-top: 40px;
    margin-bottom: 24px;
  }
}

.category__categories-without-icons {
  @media only screen and (max-width: 767px) {
    text-align: center;
  }
}

.col.category__back-to-all-projects {
  font-size: 24px;
  line-height: 32px;
  margin-top: -40px;
  padding-bottom: 35px;

  @media only screen and (max-width: 767px) {
    font-size: 18px;
    margin-top: -40px;
    padding-bottom: 20px;
  }
}

.category__long-arrow-left::after {
  content: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAYAAADEtGw7AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAElSURBVHgBtZSxUcMwFIY/2QyQEVJSgTFNylCyhZKDOWLvEcAZgTIdLIA9gkdIyV0OxBOQO1+Io2dx+SrJkj/pnv9nUHKPzRiA0Wy6Y74AV8jmdou7qaja0DuJVurHDsZnmCcUJFrpDoPZ8B/xIanQbPmcocBopQ63eqCyKDGnkHqSkFTm5VCpx4SkS6qCCEy/9DteLQMR4cbgZmaOtYkym3pcGWyQWH5LYQsZLv6cG1EKz4e0fefjHZKbYsljSQTpbvBG85KTyUFm2lmf5uSyVr8ykLQ7OSIfiXzNANL9Bz3yyRX5uKZ+JlbcJ5dJdk2eXXC5bmjeiRH3yYVzyfxE1lYEOJrjn3Z2+6kYoSANbeje3OdaMnorpVD97FVIzqcWq7qt5wu5nHNNe3sLIQAAAABJRU5ErkJggg==');
  padding-right: 10px;
}
