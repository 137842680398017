.modal {

  .btn-close {
    position: absolute;
    top: 36px;
    right: 28px;
  }

  .title {
    font-weight: bold;
    font-size: 40px;
    line-height: 56px;
    text-decoration: none;
  }

  .assignee {
    margin-top: 16px;
    line-height: 24px;
  }

  .tags-list {
    margin-bottom: 16px;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }

  .tag {
    background: #F6E8F0;
    color: #991C65;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    padding: 8px 16px;
    border-radius: 64px;
    margin-top: 8px;
    border: none;
    cursor: default;
  }

  .share {
    padding-bottom: 32px;
    position: relative;
    display: inline-block;
    padding: 16px calc(var(--bs-gutter-x) * .5);
    border-top: 1px solid rgba(192, 199, 206, 0.4);
  }

  .modal-stats-wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    padding: 16px calc(var(--bs-gutter-x) * .5);
    border-top: 1px solid rgba(192, 199, 206, 0.4);
    border-bottom: 1px solid rgba(192, 199, 206, 0.4);
  }

  .date-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .date {
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
    margin-bottom: 16px;
  }

  .date-description {
    color: #C0C7CE;
    font-size: 10px;
    line-height: 16px;
    font-weight: 700;
    text-transform: uppercase;
  }

  .modal-content {
    padding: 96px;
    position: relative;
  }

  .modal-content #modal-body {
    color: #000000;
    font-size: 18px;
    line-height: 32px;
    font-weight: 400;
    word-break: break-word;
  }

  /* Pie chart */
  .pie-chart .chart {
    margin: unset !important;
  }

  .chart {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #E5E5E5;
    position: relative;
  }

  .chart.gt-50 {
    background: linear-gradient(102.27deg, #640061 14.56%, #DE4169 170.91%);
  }

  .chart .ppc-progress {
    content: "";
    position: absolute;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    clip: rect(0, 100px, 100px, 50px);
  }

  .chart .ppc-progress .ppc-progress-fill {
    content: "";
    position: absolute;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    clip: rect(0, 50px, 100px, 0);
    background: linear-gradient(100.27deg, #640061 14.56%, #DE4169 170.91%);
    transform: rotate(60deg);
  }

  .gt-50 .ppc-progress {
    clip: rect(0, 50px, 100px, 0);
  }

  .gt-50 .ppc-progress .ppc-progress-fill {
    clip: rect(0, 100px, 100px, 50px);
    background: #F2F4F5;
  }

  .chart .ppc-percents {
    content: "";
    position: absolute;
    border-radius: 50%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 84px;
    height: 84px;
    background: #fff;
    text-align: center;
    display: table;
    overflow: hidden;
  }

  .chart .pcc-percents-wrapper {
    display: table-cell;
    vertical-align: middle;
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
  }

  @media (min-width: 576px) {
    .modal-dialog {
      max-width: 928px;
    }
  }

  @media (max-width: 575px) {
    .modal-stats-wrapper {
      display: block;
      position: relative;
    }

    .date-wrapper {
      align-items: flex-start;
    }

    .date-wrapper:first-child {
      margin-bottom: 8px;
    }

    .date {
      margin-bottom: 0;
    }

    .pie-chart {
      position: absolute;
      right: 16px;
      top: 16px;
    }
  }

  @media only screen and (max-width: 767px) {
    .modal-content {
      padding: 96px 24px 24px 24px;
    }
  }
}
