.projectListItem {
  padding: 24px;
  position: relative;

  &:not(:last-child) {
    border-bottom: 1px solid rgba(192, 199, 206, 0.4);
  }

  & .project-container {
    display: inline-grid;
    grid-template-columns: 400px 1fr 1fr 1fr;
    width: 100%;
  }

  & .title {
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
    cursor: pointer;
  }

  & .assignee {
    line-height: 24px;
    margin-top: 8px;
  }

  & .share {
    position: relative;
    display: inline-block;
    margin-top: 16px;
    .coppied {
      left: 76px;
      top: 34px;
      min-width: 170px;
    }
  }

  & .show-more {
    display: inline-block;
    color: #DC3B63;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 16px;
  }

  & .date {
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
  }

  & .date-description {
    color: #C0C7CE;
    font-size: 10px;
    line-height: 16px;
    font-weight: 700;
    text-transform: uppercase;
  }

  .date-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    & .date {
      margin-bottom: 4px;
    }
  }

  @media only screen and (max-width: 1200px) {
    padding: 16px;

    & .project-container {
      display: block;
    }

    .share {
      margin-bottom: 16px;
    }

    .date-wrapper {
      align-items: unset;
    }

    .date-wrapper:first-child {
      margin-top: 16px;
    }

    .date-wrapper+.date-wrapper {
      margin-top: 8px;
    }

    .show-more-1 {
      display: none;
    }
  }

  @media only screen and (max-width: 767px) {
    .date {
      font-size: 20px;
      margin-bottom: 4px;
    }
  }

  .tags-list {
    margin-bottom: 16px;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;

    & .tag {
      background: #F6E8F0;
      color: #991C65;
      font-size: 12px;
      line-height: 16px;
      font-weight: 400;
      padding: 8px 16px;
      border-radius: 64px;

      &.active {
        color: #FFFFFF;
        background: linear-gradient(71.03deg, #640061 24.74%, #DE4169 155.32%);
      }
    }
  }


  /* Pie chart */
  .pie-chart {
    position: absolute;
    right: 24px;
    top: 50%;
    transform: translate(0, -50%);

    .chart {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      background-color: #E5E5E5;
      position: relative;
    }

    .chart.gt-50 {
      background: linear-gradient(102.27deg, #640061 14.56%, #DE4169 170.91%);
    }

    .ppc-progress {
      content: "";
      position: absolute;
      border-radius: 50%;
      width: 100px;
      height: 100px;
      clip: rect(0, 100px, 100px, 50px);

      .ppc-progress-fill {
        content: "";
        position: absolute;
        border-radius: 50%;
        width: 100px;
        height: 100px;
        clip: rect(0, 50px, 100px, 0);
        background: linear-gradient(100.27deg, #640061 14.56%, #DE4169 170.91%);
        transform: rotate(360deg);
      }
    }

    .gt-50 .ppc-progress {
      clip: rect(0, 50px, 100px, 0);

      .ppc-progress-fill {
        clip: rect(0, 100px, 100px, 50px);
        background: #F2F4F5;
      }
    }

    .ppc-percents {
      content: "";
      position: absolute;
      border-radius: 50%;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 84px;
      height: 84px;
      background: #fff;
      text-align: center;
      display: table;
      overflow: hidden;
    }

    .pcc-percents-wrapper {
      display: table-cell;
      vertical-align: middle;
      font-size: 24px;
      line-height: 32px;
      font-weight: 700;
    }

    @media only screen and (max-width: 1200px) {
      right: 16px;
      bottom: 48px;
      transform: none;
      top: auto;
    }
  }
}
