.councilorSelect {
  background: #ffffff;
  color: #991C65;
  font-size: 16px;
  line-height: 16px;
  font-weight: 400;
  border: 0;
  padding: 16px 64px 16px 24px;
  box-shadow: 10px 12px 80px rgba(100, 0, 97, 0.2);
  border-radius: 8px;
  appearance: none;

  @media only screen and (max-width: 767px) {
    width: 100%;
    font-size: 14px;
  }
}

.councilorSelect__select-chevron {
  position: absolute;
  right: 24px;
  top: 50%;
  transform: translate(0, -50%);
  pointer-events: none;
}

.select-wrapper {
  @media only screen and (max-width: 767px) {
    width: 100%;
  }
}
