.categoryButton {
  background: #FFFFFF;
  box-shadow: 10px 28px 80px rgb(71 24 70 / 10%);
  border-radius: 16px;
  padding: 24px 0 24px 24px;
  width: 252px;
  font-weight: bold;
  font-size: 22px;
  line-height: 24px;
  display: flex;
  align-items: center;
  transition: box-shadow .4s ease;

  & span {
    max-width: 168px;
  }

  &:hover {
    text-decoration: none;
    box-shadow: 10px 28px 80px rgb(100 0 97 / 23%);
    z-index: 1;
  }

  @media only screen and (max-width: 1200px) {
    text-align: center;
    flex-direction: column;
    width: 25%;
    padding: 24px 16px;
    box-shadow: none;
    font-size: 16px;
    line-height: 24px;
  }

  @media only screen and (max-width: 767px) {
    width: 33%;
  }
}

.categoryButton__icon {
  margin-right: 16px;

  @media only screen and (max-width: 1200px) {
    margin-right: 0;
    margin-bottom: 8px;
  }
}
