button {
  display: inline-block;
  vertical-align: middle;
  color: #f00658;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 700;
  padding-left: 8px;

  &:hover {
    text-decoration: none;
  }

  img {
    margin-right: 5px;
  }
}

.coppied {
  background: #f00658;
  font-weight: bold;
  color: #fff;
  padding: 8px 16px;
  font-weight: bold;
  border-radius: 8px;
  transition: all 0.4s ease;
  position: absolute;
  left: 85px;
  transform: translateX(-50%);
  top: 48px;
  text-align: center;
  min-width: 145px;
  margin-left: 8px;
  z-index: 20;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 28%;
    transform: translate(-50%, -50%);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 20px 20px 20px;
    border-color: transparent transparent #f00658 transparent;
  }
}
